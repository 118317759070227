import {
  isApiException,
  isValidationProblemDetails,
} from '@dakota/client-common';
import {
  AddMediaParams,
  CreateInspectionRequest,
  FilesClient,
  InspectionsClient,
  ListInspectionsParams,
  Priority,
  PromptComment,
  PromptMedia,
  PromptResponse,
  ReportsClient,
  SecuredFileParams,
  UpdateInspectionRequest,
} from '@dakota/platform-client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ClientData, getClient } from 'features/clientProvider';

export const listInspections = createAsyncThunk(
  'inspections/listInspections',
  async (params: ClientData & ListInspectionsParams) => {
    const client = getClient(InspectionsClient, params);
    const response = await client.listInspections(params);
    return response.result;
  },
);

export const getInspectionDetails = createAsyncThunk(
  'inspections/getInspectionDetails',
  async (params: { id: string } & ClientData) => {
    const client = getClient(InspectionsClient, params);
    const response = await client.getInspectionDetails(params.id);
    return response.result;
  },
);

export const createInspection = createAsyncThunk(
  'inspections/createInspection',
  async (params: ClientData & CreateInspectionRequest) => {
    const client = getClient(InspectionsClient, params);

    const response = await client.createInspection(params);
    return response.result;
  },
);

export const startInspection = createAsyncThunk(
  'inspections/startInspection',
  async (params: { id: string } & ClientData) => {
    const client = getClient(InspectionsClient, params);
    await client.startInspection(params.id);

    // Starting the inspection doesn't fill the inspection state object
    // with sections/zones, so we need to re-fetch it.
    const response = await client.getInspectionDetails(params.id);
    return response.result;
  },
);

export const cancelInspection = createAsyncThunk(
  'inspections/cancelInspection',
  async (params: { id: string } & ClientData) => {
    const client = getClient(InspectionsClient, params);
    await client.cancelInspection(params.id);
  },
);

export const updateInspection = createAsyncThunk(
  'inspections/updateInspection',
  async (
    params: {
      id: string;
      priority: Priority;
      scheduledDate: string;
      userId: string | undefined;
      zones: string[];
    } & ClientData,
  ) => {
    const client = getClient(InspectionsClient, params);
    const updateRequest = new UpdateInspectionRequest({
      priority: params.priority,
      scheduledDate: params.scheduledDate,
      userId: params.userId,
      zones: params.zones,
    });
    const response = await client.updateInspection(params.id, updateRequest);
    return response.result;
  },
);

export const saveResponses = createAsyncThunk(
  'inspections/saveResponses',
  async (
    params: { inspectionId: string; responses: PromptResponse[] } & ClientData,
  ) => {
    const client = getClient(InspectionsClient, params);
    await client.respond(params.inspectionId, params.responses);
  },
);

export const completeInspection = createAsyncThunk(
  'inspections/completeInspection',
  async (params: { id: string } & ClientData) => {
    const client = getClient(InspectionsClient, params);
    await client.completeInspection(params.id);

    // Completing the inspection doesn't return the inspection object
    // so we need to re-fetch it to display completion date.
    const response = await client.getInspectionDetails(params.id);
    return response.result;
  },
);

export const exportInspectionNotes = createAsyncThunk(
  'inspections/exportNotes',
  async (params: { id: string } & ClientData) => {
    const client = getClient(ReportsClient, params);

    const response = await client.getInspectionCommentsReport(params.id);
    return response.result;
  },
);

export const addNote = createAsyncThunk(
  'inspections/addNote',
  async (
    params: {
      inspectionId: string;
      promptId: string;
      promptIndex: number;
      sectionIndex: number;
      text: string;
    } & ClientData,
  ) => {
    const client = getClient(InspectionsClient, params);
    const response = await client.addCommentToPrompt(
      params.inspectionId,
      params.promptId,
      { text: params.text } as PromptComment,
    );
    return response.result;
  },
);

export const updateNote = createAsyncThunk(
  'inspections/updateNote',
  async (
    params: {
      commentId: string;
      inspectionId: string;
      noteIndex: number;
      promptId: string;
      promptIndex: number;
      sectionIndex: number;
      text: string;
    } & ClientData,
  ) => {
    const client = getClient(InspectionsClient, params);
    const response = await client.updateCommentForPrompt(
      params.inspectionId,
      params.promptId,
      params.commentId,
      { text: params.text } as PromptComment,
    );
    return response.result;
  },
);

export const deleteNote = createAsyncThunk(
  'inspections/deleteNote',
  async (
    params: {
      commentId: string;
      inspectionId: string;
      noteIndex: number;
      promptId: string;
      promptIndex: number;
      sectionIndex: number;
    } & ClientData,
  ) => {
    const client = getClient(InspectionsClient, params);
    await client.deleteCommentForPrompt(
      params.inspectionId,
      params.promptId,
      params.commentId,
    );
  },
);

export const addAttachment = createAsyncThunk(
  'inspections/addAttachment',
  async (
    params: {
      inspectionId: string;
      options: AddMediaParams;
      promptId: string;
      promptIndex: number;
      sectionIndex: number;
    } & ClientData,
    { rejectWithValue },
  ) => {
    const client = getClient(InspectionsClient, params);
    try {
      const response = await client.addMediaToPrompt(
        params.inspectionId,
        params.promptId,
        params.options,
      );
      return response.result;
    } catch (e: unknown) {
      if (isApiException(e) && isValidationProblemDetails(e.result)) {
        // Confirmed with the Backend, it is 'uber' rare that we would see more
        // than one error in this array
        return rejectWithValue(e.result.errors.mediaFile[0]);
      }
      throw e;
    }
  },
);

export const updateAttachment = createAsyncThunk(
  'inspections/updateAttachment',
  async (
    params: {
      attachmentId: string;
      attachmentIndex: number;
      description: string;
      inspectionId: string;
      promptId: string;
      promptIndex: number;
      sectionIndex: number;
    } & ClientData,
  ) => {
    const client = getClient(InspectionsClient, params);
    const body = {
      description: params.description || undefined,
    } as PromptMedia;
    const response = await client.updateMediaForPrompt(
      params.inspectionId,
      params.promptId,
      params.attachmentId,
      body,
    );
    return response.result;
  },
);

export const deleteAttachment = createAsyncThunk(
  'inspections/deleteAttachment',
  async (
    params: {
      attachmentId: string;
      attachmentIndex: number;
      inspectionId: string;
      promptId: string;
      promptIndex: number;
      sectionIndex: number;
    } & ClientData,
  ) => {
    const client = getClient(InspectionsClient, params);
    await client.deleteMediaForPrompt(
      params.inspectionId,
      params.promptId,
      params.attachmentId,
    );
  },
);

export const getFile = createAsyncThunk(
  'inspections/getFile',
  async (params: { attachmentId: string } & ClientData & SecuredFileParams) => {
    const client = getClient(FilesClient, params);

    const response = await client.getSecuredFile(params);
    return response.result;
  },
);
