import {
  Summary,
  TaskInstance,
  TaskState,
  UserSummary,
} from '@dakota/platform-client';
import { PlusIcon } from '@heroicons/react/24/outline';
import Skeleton from '@mui/material/Skeleton';
import Autocomplete from 'components/Autocomplete';
import Button from 'components/Button';
import { ClearAllButton } from 'components/ClearAll';
import { DatePicker } from 'components/DatePicker';
import { PageHeader } from 'components/PageHeader';
import SearchInput from 'components/SearchInput';
import { tasksSlice } from 'features/tasks/tasksSlice';
import { userSlice } from 'features/user/userSlice';
import { useFilteredTasks } from 'hooks/useFilteredTasks';
import { DataStatus, usePageLoadTracking } from 'hooks/usePageLoadTracking';
import { useUsers } from 'hooks/useUsers';
import { useViewToggle } from 'hooks/useViewToggle';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { TaskCard } from '../Card';
import { CreateTask } from '../CreateTask';
import { TaskLoader } from '../TaskLoader';
import TasksTable from '../TasksTable';
import { ExtendedTaskState, getStatusLabel } from '../types';

export const ScheduledTasks: FC = () => {
  const accessibleFacilities = useSelector(
    userSlice.selectors.accessibleFacilities,
  );

  const { activeUsers } = useUsers();

  const [taskInstance, setTaskInstance] = useState<TaskInstance | undefined>();
  const [isCreatePanelOpen, setIsCreatePanelOpen] = useState(false);

  const { isListView, viewToggleButtons } = useViewToggle('scheduledTasksView');

  const { stopTracking } = usePageLoadTracking();

  const loadingTasks = useSelector(tasksSlice.selectors.isLoadingTasks);

  const allTasks = useSelector(tasksSlice.selectors.tasks);

  const [selectedFacilities, setSelectedFacilities] = useState<Summary[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<TaskState[]>([]);
  const [selectedAssignees, setSelectedAssignees] = useState<UserSummary[]>([]);
  const [searchQuery, setSearchQuery] = useState('');

  const {
    dateRange,
    hasFilters,
    hookDataLoaded,
    resetDateRange,
    setDateRange,
  } = useFilteredTasks({
    searchQuery,
    selectedAssignees,
    selectedFacilities,
    selectedStatus,
  });

  const statuses: ExtendedTaskState[] = [
    TaskState.InProgress,
    TaskState.Scheduled,
    'overdue',
  ];

  useEffect(() => {
    if (hookDataLoaded) {
      stopTracking(DataStatus.Fetched);
    }
  }, [hookDataLoaded, stopTracking]);

  const resetFilters = () => {
    setSelectedFacilities([]);
    setSelectedStatus([]);
    setSelectedAssignees([]);
    setSearchQuery('');
    resetDateRange();
  };

  return (
    <div className='p-4 sm:p-8'>
      <PageHeader title='Scheduled Tasks'>
        <Button
          className='bg-green-base px-4 py-2'
          icon={<PlusIcon />}
          onClick={() => setIsCreatePanelOpen(true)}
        >
          Create New Task
        </Button>
      </PageHeader>
      <div className='flex justify-between items-center gap-2'>
        <div className='filters-container'>
          <SearchInput
            aria-label='Search tasks'
            id='scheduled-tasks-search-input'
            onSearch={setSearchQuery}
            value={searchQuery}
          />
          <DatePicker
            asSingle={false}
            id='scheduled-tasks-date-picker'
            onChange={setDateRange}
            scrollOnFocus={false}
            shortcutsType='next'
            showShortcuts
            value={dateRange}
          />
          <Autocomplete
            className='max-sm:w-full w-48'
            getOptionKey={(facility) => facility.id}
            getOptionLabel={(facility) => facility.name}
            id='scheduled-tasks-facility-selector'
            label='Facilities'
            multiple
            onChange={setSelectedFacilities}
            options={accessibleFacilities}
            value={selectedFacilities}
          />
          <Autocomplete
            className='max-sm:w-full w-44'
            getOptionKey={(user) => user.id}
            getOptionLabel={(user) => user.name}
            id='scheduled-tasks-assignee-selector'
            label='Assignee'
            multiple
            onChange={setSelectedAssignees}
            options={activeUsers}
            value={selectedAssignees}
          />
          <Autocomplete
            className='max-sm:w-full w-40'
            getOptionLabel={getStatusLabel}
            id='scheduled-tasks-status-selector'
            label='Status'
            multiple
            onChange={setSelectedStatus}
            options={statuses}
            value={selectedStatus}
          />
          {hasFilters && <ClearAllButton onClick={resetFilters} />}
        </div>
        {viewToggleButtons}
      </div>
      {isListView && (
        <TasksTable
          data={allTasks}
          loading={loadingTasks}
          openTaskInstance={setTaskInstance}
          setTaskInstanceStatus={console.log}
        />
      )}
      {!isListView && loadingTasks && (
        <div aria-label='Loading task cards' className='space-y-4'>
          <Skeleton animation='pulse' height={200} variant='rounded' />
          <Skeleton animation='pulse' height={200} variant='rounded' />
        </div>
      )}
      {!isListView && !loadingTasks && (
        <div className='space-y-4'>
          {allTasks.map((task) => (
            <TaskCard
              key={task.id}
              openTaskInstance={setTaskInstance}
              setTaskInstanceStatus={console.log}
              task={task}
            />
          ))}
        </div>
      )}
      {taskInstance && (
        <TaskLoader
          onClose={() => setTaskInstance(undefined)}
          taskInstance={taskInstance}
        />
      )}
      {isCreatePanelOpen && (
        <CreateTask onClose={() => setIsCreatePanelOpen(false)} />
      )}
    </div>
  );
};
