import {
  Inspection,
  InspectionStatus,
  Summary,
  UserSummary,
} from '@dakota/platform-client';
import { PlusIcon } from '@heroicons/react/20/solid';
import PermissionGuard from 'auth/PermissionGuard';
import Autocomplete from 'components/Autocomplete';
import Button from 'components/Button';
import Chips from 'components/Chip/Chips';
import { ClearAllButton } from 'components/ClearAll';
import { DatePicker } from 'components/DatePicker';
import InspectionsTable from 'components/Inspections/InspectionsTable';
import { PageHeader } from 'components/PageHeader';
import SearchInput from 'components/SearchInput';
import { inspectionSlice } from 'features/inspections/inspectionSlice';
import { userSlice } from 'features/user/userSlice';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { useFilteredInspections } from 'hooks/useFilteredInspections';
import { DataStatus, usePageLoadTracking } from 'hooks/usePageLoadTracking';
import { useUsers } from 'hooks/useUsers';
import { useViewToggle } from 'hooks/useViewToggle';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FeatureFlag } from 'utils/featureFlags';
import { getStatusLabel } from 'utils/inspectionStatusLabel';
import { Permission } from 'utils/permissions';

import { InspectionCard } from '../Card';

const ScheduledInspections = () => {
  const isTasksEnabled = useFeatureFlag(FeatureFlag.Tasks);

  const isLoadingInspections = useSelector(
    inspectionSlice.selectors.isLoadingInspections,
  );
  const accessibleFacilities = useSelector(
    userSlice.selectors.accessibleFacilities,
  );
  const { activeUsers } = useUsers();
  const isMobile = useSelector(userSlice.selectors.isMobile);

  const [selectedStatus, setSelectedStatus] = useState<InspectionStatus[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedFacilities, setSelectedFacilities] = useState<Summary[]>([]);
  const [selectedAssignees, setSelectedAssignees] = useState<UserSummary[]>([]);

  const { stopTracking } = usePageLoadTracking();

  const navigate = useNavigate();
  const location = useLocation();

  const statuses = [
    InspectionStatus.InProgress,
    InspectionStatus.Overdue,
    InspectionStatus.Scheduled,
  ];

  const {
    dateRange,
    filteredInspections,
    hasFilters,
    hookDataLoaded,
    resetDateRange,
    setDateRange,
  } = useFilteredInspections({
    searchQuery,
    selectedAssignees,
    selectedFacilities,
    selectedStatus,
  });

  useEffect(() => {
    if (hookDataLoaded) {
      stopTracking(DataStatus.Fetched);
    }
  }, [hookDataLoaded, stopTracking]);

  const { isListView, viewToggleButtons } = useViewToggle(
    'scheduledInspections',
  );

  const resetFilters = () => {
    setSelectedFacilities([]);
    setSelectedStatus([]);
    setSelectedAssignees([]);
    setSearchQuery('');
    resetDateRange();
  };

  const removeFacility = (facility: Summary) => {
    setSelectedFacilities(selectedFacilities.filter((f) => f !== facility));
  };

  const removeAssignee = (assignee: UserSummary) => {
    setSelectedAssignees(selectedAssignees.filter((a) => a !== assignee));
  };

  const openInspection = (inspection: Inspection) => {
    navigate(`/inspections/${inspection.id}?source=${location.pathname}`);
  };

  return (
    <div className='p-4 sm:p-8'>
      <PageHeader scaffold={['Scheduled', 'Inspections']} title='Scheduled'>
        <PermissionGuard permissions={Permission.CreateInspection}>
          <div className='flex gap-2.5 items-center'>
            <Link to='/manage/inspection-templates'>
              <Button
                className='bg-green-base px-4 py-2'
                data-testid='schedule-inspection-button'
                icon={<PlusIcon />}
              >
                Schedule Inspection
              </Button>
            </Link>
          </div>
        </PermissionGuard>
      </PageHeader>
      <div className='flex justify-between items-center gap-2'>
        <div className='filters-container'>
          <SearchInput
            data-testid='search-input'
            id='scheduled-inspections-search-input'
            onSearch={setSearchQuery}
            value={searchQuery}
          />
          <DatePicker
            asSingle={false}
            id='scheduled-inspections-date-picker'
            onChange={setDateRange}
            scrollOnFocus={false}
            shortcutsType='next'
            showShortcuts
            value={dateRange}
          />
          <Autocomplete
            className='w-60'
            getOptionKey={(facility) => facility.id}
            getOptionLabel={(facility) => facility.name}
            id='scheduled-inspections-facility-selector'
            label='Facilities'
            multiple
            onChange={setSelectedFacilities}
            options={accessibleFacilities}
            value={selectedFacilities}
          />
          <Autocomplete
            className='max-sm:w-60 w-40'
            getOptionKey={(user) => user.id}
            getOptionLabel={(user) => user.name}
            id='scheduled-inspections-assignee-selector'
            label='Assignee'
            multiple
            onChange={setSelectedAssignees}
            options={activeUsers}
            value={selectedAssignees}
          />
          <Autocomplete
            className='max-sm:w-60 w-32'
            getOptionLabel={getStatusLabel}
            id='scheduled-inspections-status-selector'
            label='Status'
            multiple
            onChange={setSelectedStatus}
            options={statuses}
            value={selectedStatus}
          />
          {hasFilters && <ClearAllButton onClick={resetFilters} />}
        </div>
        {isTasksEnabled && viewToggleButtons}
      </div>
      {selectedFacilities.length > 0 && (
        <Chips
          elements={selectedFacilities}
          getKey={(f) => f.id}
          getLabel={(f) => f.name}
          onRemove={removeFacility}
          title='Facilities'
        />
      )}
      {selectedAssignees.length > 0 && (
        <Chips
          elements={selectedAssignees}
          getKey={(a) => a.id}
          getLabel={(a) => a.name}
          onRemove={removeAssignee}
          title='Assignees'
        />
      )}
      {isMobile || isListView || !isTasksEnabled ? (
        <InspectionsTable
          data={filteredInspections}
          loading={isLoadingInspections}
          searchQuery={searchQuery}
        />
      ) : (
        <div className='space-y-4'>
          {filteredInspections.map((inspection) => (
            <InspectionCard
              inspection={inspection}
              key={inspection.id}
              openInspection={() => openInspection(inspection)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ScheduledInspections;
