import { PlusIcon } from '@heroicons/react/24/outline';
import { FC } from 'react';
import { noOp } from 'utils/functional';

export const Attachments: FC = () => {
  return (
    <div className='flex flex-col gap-4'>
      <div>
        <div className='text-base font-bold'>Attachments</div>
        <div className='text-xs text-gray-400'>
          Image and PDF files up to 20MB
        </div>
      </div>
      <button
        className='flex gap-2 items-center text-green-base'
        onClick={noOp}
      >
        <PlusIcon className='w-5' /> Add Attachments
      </button>
    </div>
  );
};
