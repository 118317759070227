import { Priority } from '@dakota/platform-client';
import {
  Bars2Icon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/16/solid';

type PriorityIconProps = {
  priority: Priority;
};

export const PriorityIcon = ({ priority }: PriorityIconProps) => {
  const props = { className: 'flex items-center font-bold' };

  if (priority === Priority.Low) {
    return (
      <span {...props}>
        <ChevronDownIcon className='w-5 h-5 text-blue-base' />
      </span>
    );
  }
  if (priority === Priority.Medium) {
    return (
      <span {...props}>
        <Bars2Icon className='w-5 h-5 text-yellow-base' />
      </span>
    );
  }
  return (
    <span {...props}>
      <ChevronUpIcon className='w-5 h-5 text-red-base' />
    </span>
  );
};
