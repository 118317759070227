import { Facility, InspectionStatus } from '@dakota/platform-client';
import Autocomplete from 'components/Autocomplete';
import Chips from 'components/Chip/Chips';
import { ClearAllButton } from 'components/ClearAll';
import { DatePicker } from 'components/DatePicker';
import InspectionsTable from 'components/Inspections/InspectionsTable';
import { PageHeader } from 'components/PageHeader';
import SearchInput from 'components/SearchInput';
import { configSlice } from 'features/config/configSlice';
import { getAllFacilities } from 'features/facilities/facilitiesActions';
import { facilitiesSlice } from 'features/facilities/facilitiesSlice';
import { inspectionSlice } from 'features/inspections/inspectionSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import { userSlice } from 'features/user/userSlice';
import { useFilteredInspections } from 'hooks/useFilteredInspections';
import { DataStatus, usePageLoadTracking } from 'hooks/usePageLoadTracking';
import { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { alphabeticalCompare } from 'utils/functional';
import { getStatusLabel } from 'utils/inspectionStatusLabel';

const Assignments: FC = () => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const assigneeId = useSelector(userSlice.selectors.currentUser).id;

  const isLoadingInspections = useSelector(
    inspectionSlice.selectors.isLoadingInspections,
  );

  const allFacilities = useSelector(facilitiesSlice.selectors.allFacilities);
  const isLoadingFacilities = useSelector(
    facilitiesSlice.selectors.isLoadingAllFacilities,
  );

  const [selectedFacilities, setSelectedFacilities] = useState<Facility[]>([]);

  const selectableFacilities = useMemo(
    () => allFacilities.toSorted(alphabeticalCompare((e) => e.name)),
    [allFacilities],
  );
  const [selectedStatus, setSelectedStatus] = useState<InspectionStatus[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [pageDataLoaded, setPageDataLoaded] = useState(false);

  const { stopTracking } = usePageLoadTracking();

  const statuses = [
    InspectionStatus.InProgress,
    InspectionStatus.Overdue,
    InspectionStatus.Scheduled,
  ];

  const {
    dateRange,
    filteredInspections,
    hasFilters,
    hookDataLoaded,
    resetDateRange,
    setDateRange,
  } = useFilteredInspections({
    assigneeId,
    searchQuery,
    selectedFacilities,
    selectedStatus,
  });

  useEffect(
    () =>
      void dispatch(getAllFacilities({ baseUrl, token })).then(() =>
        setPageDataLoaded(true),
      ),
    [baseUrl, dispatch, token],
  );

  useEffect(() => {
    if (hookDataLoaded && pageDataLoaded) {
      stopTracking(DataStatus.Fetched);
    }
  }, [hookDataLoaded, pageDataLoaded, stopTracking]);

  const removeFacility = (facility: Facility) => {
    setSelectedFacilities((prev) => prev.filter((f) => f.id !== facility.id));
  };

  const resetFilters = () => {
    setSelectedFacilities([]);
    setSelectedStatus([]);
    resetDateRange();
    setSearchQuery('');
  };

  return (
    <div className='p-4 sm:p-8'>
      <PageHeader scaffold={['My Assignments', 'Dashboard']} />
      <div className='filters-container'>
        <SearchInput
          data-testid='search-input'
          id='search-input'
          onSearch={setSearchQuery}
          value={searchQuery}
        />
        <DatePicker
          asSingle={false}
          id='assignments-date-picker'
          onChange={setDateRange}
          scrollOnFocus={false}
          shortcutsType='next'
          showShortcuts
          value={dateRange}
        />
        <Autocomplete
          className='max-sm:w-full w-44'
          getOptionKey={(facility) => facility.id}
          getOptionLabel={(facility) => facility.name}
          id='assignments-facility-selector'
          label='Facilities'
          loading={isLoadingFacilities}
          multiple
          onChange={setSelectedFacilities}
          options={selectableFacilities}
          value={selectedFacilities}
        />
        <Autocomplete
          className='max-sm:w-full w-44'
          getOptionLabel={getStatusLabel}
          id='assignments-status-selector'
          label='Status'
          multiple
          onChange={setSelectedStatus}
          options={statuses}
          value={selectedStatus}
        />
        {hasFilters && <ClearAllButton onClick={resetFilters} />}
      </div>
      <Chips
        elements={selectedFacilities}
        getKey={(f) => f.id}
        getLabel={(f) => f.name}
        onRemove={removeFacility}
        title='Facilities'
      />
      <InspectionsTable
        data={filteredInspections}
        loading={isLoadingInspections}
      />
    </div>
  );
};

export default Assignments;
