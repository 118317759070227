import NewNote from 'components/Note/NewNote';
import { taskEditSlice } from 'features/tasks/taskEditSlice';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

export const Note: FC = () => {
  const dispatch = useAppDispatch();

  const note = useSelector(taskEditSlice.selectors.note);

  return (
    <>
      <div className='font-bold'>Notes</div>
      <NewNote
        id='task-initial-note'
        maxNoteLength={1000}
        note={note || 'Add an optional note to your task'}
        onChange={(value) =>
          dispatch(taskEditSlice.actions.setTaskField({ field: 'note', value }))
        }
      />
    </>
  );
};
