import { userSlice } from 'features/user/userSlice';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { alphabeticalCompare } from 'utils/functional';

export const useUsers = () => {
  const allUserSummaries = useSelector(userSlice.selectors.allUserSummaries);

  const activeUsers = allUserSummaries
    .filter((u) => !u.inactive)
    .sort(alphabeticalCompare((u) => u.name));

  /**
   * Get the Summary for a user by ID. The user may or may not be active.
   */
  const getUserSummary = useCallback(
    (userId?: string) => allUserSummaries.find((u) => u.id === userId),
    [allUserSummaries],
  );

  /**
   * Get the Summary for a user known to be active.
   */
  const getActiveUserSummary = useCallback(
    (userId?: string) => activeUsers.find((u) => u.id === userId),
    [activeUsers],
  );

  return {
    activeUsers,
    allUserSummaries,
    getActiveUserSummary,
    getUserSummary,
  };
};
