import { InspectionsClient, InspectionStatus } from '@dakota/platform-client';
import { LocalDate } from '@js-joda/core';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ClientData, getClient } from 'features/clientProvider';

/**
 * Fetch the inspections assigned to the given user
 * that are in 'Scheduled' or 'Overdue' status.
 */
export const getScheduledInspections = createAsyncThunk(
  'notifications/scheduledInspections',
  async (params: { userId: string } & ClientData) => {
    const client = getClient(InspectionsClient, params);
    const response = await client.listInspections({
      scheduleDate_end: LocalDate.now().plusDays(1).toString(),
      status: [InspectionStatus.Scheduled, InspectionStatus.Overdue],
      userId: params.userId,
    });
    return response.result;
  },
);
