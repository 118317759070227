import { Summary } from '@dakota/platform-client';
import Autocomplete from 'components/Autocomplete';
import { taskEditSlice } from 'features/tasks/taskEditSlice';
import { useZones } from 'hooks/useZones';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

export const Zone: FC = () => {
  const dispatch = useAppDispatch();

  const { facilitiesLoadingZones, getFacilityZonesSummaries } = useZones();

  const [selectableZones, setSelectableZones] = useState<Summary[]>([]);

  const facility = useSelector(taskEditSlice.selectors.facility);
  const zone = useSelector(taskEditSlice.selectors.zone);

  useEffect(() => {
    if (facility) {
      setSelectableZones(getFacilityZonesSummaries(facility.id));
    }
  }, [facility, getFacilityZonesSummaries]);

  if (selectableZones.length === 0) {
    return null;
  }

  return (
    <>
      <label htmlFor='task-zone-selector'>Zone</label>
      <Autocomplete
        clearable
        disabled={!facility}
        fullWidth
        getOptionKey={(z) => z.id}
        getOptionLabel={(z) => z.name}
        id='task-zone-selector'
        isOptionEqualToValue={(option, value) => option.id === value.id}
        loading={!!facility && facilitiesLoadingZones.includes(facility.id)}
        onChange={(value) =>
          dispatch(
            taskEditSlice.actions.setTaskField({
              field: 'zone',
              value,
            }),
          )
        }
        options={selectableZones}
        value={zone ?? null}
      />
    </>
  );
};
