import Input from 'components/Input';
import { taskEditSlice } from 'features/tasks/taskEditSlice';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

export const Title: FC = () => {
  const dispatch = useAppDispatch();

  const title = useSelector(taskEditSlice.selectors.title);

  return (
    <>
      <label className='required-field ' htmlFor='task-title-input'>
        Title
      </label>
      <div>
        <Input
          className='w-full'
          id='task-title-input'
          maxLength={50}
          onChange={(e) =>
            dispatch(
              taskEditSlice.actions.setTaskField({
                field: 'title',
                value: e.target.value,
              }),
            )
          }
          placeholder='Task Title'
          value={title}
        />
      </div>
    </>
  );
};
