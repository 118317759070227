import { PlusIcon } from '@heroicons/react/24/outline';
import { FC, useRef } from 'react';

export const Attachments: FC = () => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <div>
        <div className='font-bold'>Attachments</div>
        <div className='text-xs text-gray-400'>
          Image and PDF files up to 20MB
        </div>
      </div>
      <div>
        <input
          className='hidden'
          data-testid='file-upload-input'
          multiple
          onChange={() => console.log('handleFileEvent')}
          ref={fileInputRef}
          type='file'
        />
        <button
          className='flex gap-2 items-center text-green-base'
          onClick={() => fileInputRef.current?.click()}
        >
          <PlusIcon className='w-5' /> Add Attachments
        </button>
      </div>
    </>
  );
};
