import { Inspection, InspectionStatus } from '@dakota/platform-client';
import { LocalDate } from '@js-joda/core';
import { createSlice } from '@reduxjs/toolkit';
import {
  cancelInspection,
  createInspection,
  startInspection,
  updateInspection,
} from 'features/inspections/inspectionActions';

import { getScheduledInspections } from './notificationsActions';

export type NotificationsState = {
  /**
   * The inspections that are in 'Scheduled' or 'Overdue' status
   * and are assigned to the current user.
   */
  inspections: Inspection[];
};

const initialState: NotificationsState = {
  inspections: [],
};

export const notificationsSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getScheduledInspections.fulfilled, (state, action) => {
      state.inspections = action.payload;
    });
    builder.addCase(createInspection.fulfilled, (state, action) => {
      const inspection = action.payload;
      if (
        !LocalDate.parse(inspection.timeline.scheduledDate).isAfter(
          LocalDate.now(),
        )
      ) {
        state.inspections.push(inspection);
      }
    });
    builder.addCase(updateInspection.fulfilled, (state, action) => {
      const inspection = action.payload;
      if (
        !LocalDate.parse(inspection.timeline.scheduledDate).isAfter(
          LocalDate.now(),
        ) &&
        (inspection.status === InspectionStatus.Scheduled ||
          inspection.status === InspectionStatus.Overdue)
      ) {
        state.inspections.push(inspection);
      } else {
        // Remove the inspection from the list if it's not scheduled or overdue
        state.inspections = state.inspections.filter(
          (i) => i.id !== inspection.id,
        );
      }
    });
    builder.addCase(startInspection.fulfilled, (state, action) => {
      // Unconditionally remove an inspection that gets started
      const { id } = action.meta.arg;
      state.inspections = state.inspections.filter((i) => i.id !== id);
    });
    builder.addCase(cancelInspection.fulfilled, (state, action) => {
      // Unconditionally remove an inspection that gets canceled
      const { id } = action.meta.arg;
      state.inspections = state.inspections.filter((i) => i.id !== id);
    });
  },
  initialState,
  name: 'notifications',
  reducers: {},
  selectors: {
    pendingInspections: (state: NotificationsState) => state.inspections,
  },
});
