const avatarColors = [
  'bg-avatar-0',
  'bg-avatar-1',
  'bg-avatar-2',
  'bg-avatar-3',
  'bg-avatar-4',
  'bg-avatar-5',
  'bg-avatar-6',
] as const;

export const getColorById = (assigneeId: string) => {
  // Convert the last character from hex to decimal and get the modulo 7 to
  // determine the color. Since we have 7 colors for avatars, this will ensure
  // consistent color for a user, across refreshes and even if the user changes
  // their name.
  const numericValue = Number(`0x${assigneeId.charAt(assigneeId.length - 1)}`);
  return avatarColors[numericValue % 7];
};
