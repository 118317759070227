import {
  SearchStatus,
  Summary,
  TaskInstance,
  TaskState,
  UserSummary,
} from '@dakota/platform-client';
import { LocalDate } from '@js-joda/core';
import Skeleton from '@mui/material/Skeleton';
import Autocomplete from 'components/Autocomplete';
import { ClearAllButton } from 'components/ClearAll';
import { DatePicker, DatePickerRange } from 'components/DatePicker';
import { PageHeader } from 'components/PageHeader';
import SearchInput from 'components/SearchInput';
import { configSlice } from 'features/config/configSlice';
import { listTasks } from 'features/tasks/tasksActions';
import { tasksSlice } from 'features/tasks/tasksSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import { userSlice } from 'features/user/userSlice';
import { DataStatus, usePageLoadTracking } from 'hooks/usePageLoadTracking';
import { useUsers } from 'hooks/useUsers';
import { useViewToggle } from 'hooks/useViewToggle';
import { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

import { TaskCard } from '../Card';
import { TaskLoader } from '../TaskLoader';
import TasksTable from '../TasksTable';
import { ExtendedTaskState, getStatusLabel } from '../types';

export const CompletedTasks: FC = () => {
  const dispatch = useAppDispatch();
  const token = useSelector(tokenSlice.selectors.token);
  const baseUrl = useSelector(configSlice.selectors.backend);
  const accessibleFacilities = useSelector(
    userSlice.selectors.accessibleFacilities,
  );

  const { activeUsers } = useUsers();
  const [taskInstance, setTaskInstance] = useState<TaskInstance | undefined>();

  const { isListView, viewToggleButtons } = useViewToggle('completedTasksView');

  const { stopTracking } = usePageLoadTracking();
  const loadingTasks = useSelector(tasksSlice.selectors.isLoadingTasks);
  const allTasks = useSelector(tasksSlice.selectors.tasks);

  const [filteredTasks, setFilteredTasks] = useState<TaskInstance[]>([]);
  const [isClearButtonVisible, setIsClearButtonVisible] = useState(false);

  const currentDate = useMemo(() => LocalDate.now(), []);
  const thirtyDaysAgo = useMemo(() => currentDate.minusDays(30), [currentDate]);
  const [dateRange, setDateRange] = useState<DatePickerRange>({
    begin: thirtyDaysAgo,
    end: currentDate,
  });

  const [selectedFacilities, setSelectedFacilities] = useState<Summary[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<TaskState>(
    TaskState.Completed,
  );
  const [selectedAssignees, setSelectedAssignees] = useState<UserSummary[]>([]);
  const [searchQuery, setSearchQuery] = useState('');

  const statuses: ExtendedTaskState[] = [
    TaskState.Completed,
    TaskState.Canceled,
  ];

  useEffect(() => {
    void dispatch(
      listTasks({
        baseUrl,
        dateRange_endDate: dateRange.end.toString(),
        dateRange_startDate: dateRange.begin.toString(),
        status: [SearchStatus.Completed, SearchStatus.Canceled],
        token,
      }),
    ).then(() => stopTracking(DataStatus.Fetched));
  }, [dispatch, baseUrl, token, dateRange, stopTracking]);

  useEffect(() => {
    const filtered = allTasks.filter((task) => {
      return task.status === selectedStatus;
    });
    setFilteredTasks(filtered);
  }, [allTasks, selectedStatus]);

  const resetFilters = () => {
    setSelectedFacilities([]);
    setSelectedStatus(TaskState.Completed);
    setSelectedAssignees([]);
    setSearchQuery('');
    setDateRange({
      begin: thirtyDaysAgo,
      end: currentDate,
    });
  };

  useEffect(() => {
    setIsClearButtonVisible(
      selectedAssignees.length > 0 ||
        selectedFacilities.length > 0 ||
        selectedStatus !== TaskState.Completed ||
        !dateRange.begin.equals(thirtyDaysAgo) ||
        !dateRange.end.equals(currentDate) ||
        !!searchQuery,
    );
  }, [
    currentDate,
    searchQuery,
    selectedAssignees.length,
    dateRange.end,
    dateRange.begin,
    selectedFacilities.length,
    selectedStatus,
    thirtyDaysAgo,
  ]);

  return (
    <div className='p-4 sm:p-8'>
      <PageHeader title='Completed Tasks' />
      <div className='flex justify-between items-center gap-2'>
        <div className='filters-container'>
          <SearchInput
            aria-label='Search tasks'
            id='completed-tasks-search-input'
            onSearch={setSearchQuery}
            value={searchQuery}
          />
          <DatePicker
            asSingle={false}
            id='completed-tasks-date-picker'
            onChange={setDateRange}
            scrollOnFocus={false}
            shortcutsType='last'
            showShortcuts
            value={dateRange}
          />
          <Autocomplete
            className='max-sm:w-full w-48'
            getOptionKey={(facility) => facility.id}
            getOptionLabel={(facility) => facility.name}
            id='completed-tasks-facility-selector'
            label='Facilities'
            multiple
            onChange={setSelectedFacilities}
            options={accessibleFacilities}
            value={selectedFacilities}
          />
          <Autocomplete
            className='max-sm:w-full w-44'
            getOptionKey={(user) => user.id}
            getOptionLabel={(user) => user.name}
            id='completed-tasks-assignee-selector'
            label='Assignee'
            multiple
            onChange={setSelectedAssignees}
            options={activeUsers}
            value={selectedAssignees}
          />
          <Autocomplete
            className='max-sm:w-full w-40'
            getOptionLabel={getStatusLabel}
            id='completed-tasks-status-selector'
            label='Status'
            onChange={setSelectedStatus}
            options={statuses}
            value={selectedStatus}
          />
          {isClearButtonVisible && <ClearAllButton onClick={resetFilters} />}
        </div>
        {viewToggleButtons}
      </div>
      {isListView && (
        <TasksTable
          data={filteredTasks}
          loading={loadingTasks}
          openTaskInstance={setTaskInstance}
          setTaskInstanceStatus={console.log}
          showCompletedDate
        />
      )}
      {!isListView && loadingTasks && (
        <div aria-label='Loading task cards' className='space-y-4'>
          <Skeleton animation='pulse' height={200} variant='rounded' />
          <Skeleton animation='pulse' height={200} variant='rounded' />
        </div>
      )}
      {!isListView && !loadingTasks && (
        <div className='space-y-4'>
          {filteredTasks.map((task) => (
            <TaskCard
              key={task.id}
              openTaskInstance={setTaskInstance}
              setTaskInstanceStatus={console.log}
              task={task}
            />
          ))}
        </div>
      )}
      {taskInstance && (
        <TaskLoader
          onClose={() => setTaskInstance(undefined)}
          taskInstance={taskInstance}
        />
      )}
    </div>
  );
};
