import {
  CreateTaskRequest,
  Priority,
  Summary,
  TaskInstanceDetails,
  TaskState,
  UpdateTaskInstanceRequest,
} from '@dakota/platform-client';
import { LocalDate } from '@js-joda/core';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer';
import { unassignedUser } from 'utils/user';

/**
 * State specific to the creation and editing of a single task.
 */
type TaskEditState = {
  assigneeId: string;
  date: string;
  description: string;
  facility?: Summary;
  note: string;
  /**
   * When editing a task instance, its original scheduled date.
   */
  originalDueDate: string;
  priority: Priority;
  recurrence: string;
  reportedBy: string;
  /**
   * When editing a task instance, the ID of the series to which it belongs.
   */
  seriesId: string;
  status: TaskState;
  title: string;
  zone?: Summary;
};

const initialState: TaskEditState = {
  assigneeId: '',
  date: LocalDate.now().toString(),
  description: '',
  facility: undefined,
  note: '',
  originalDueDate: '',
  priority: Priority.Medium,
  recurrence: '',
  reportedBy: '',
  seriesId: '',
  status: TaskState.Scheduled,
  title: '',
  zone: undefined,
};

export const taskEditSlice = createSlice({
  initialState,
  name: 'taskEdit',
  reducers: {
    clear: (state) => {
      state.assigneeId = initialState.assigneeId;
      state.date = initialState.date;
      state.description = initialState.description;
      state.facility = initialState.facility;
      state.note = initialState.note;
      state.originalDueDate = initialState.originalDueDate;
      state.priority = initialState.priority;
      state.recurrence = initialState.recurrence;
      state.reportedBy = initialState.reportedBy;
      state.seriesId = initialState.seriesId;
      state.status = initialState.status;
      state.title = initialState.title;
      state.zone = initialState.zone;
    },
    load: (
      state: WritableDraft<TaskEditState>,
      action: PayloadAction<TaskInstanceDetails>,
    ) => {
      const task = action.payload;
      state.assigneeId = task.assigneeId ?? '';
      state.date = task.timeline.scheduledDate;
      state.description = task.description ?? '';
      state.facility = task.facility;
      state.note = '';
      state.originalDueDate = task.timeline.scheduledDate;
      state.priority = task.priority;
      /**
       * Some tasks have an invalid recurrence rule in the backend that was
       * created with the `RRULE:` prefix. We need to remove this when reading
       * the rule. Rules created correctly are not affected by this, as they
       * don't have that substring present.
       *
       * If the backend performs a cleanup of these values, we should remove
       * the call to `replace` below.
       *
       * See ARTEMIS-3701 for details.
       */
      state.recurrence = task.seriesRecurrence.rule.replace('RRULE:', '');
      state.reportedBy = task.reportedBy;
      state.seriesId = task.seriesId;
      state.status = task.status;
      state.title = task.title;
      state.zone = task.zone;
    },
    setTaskField: <K extends keyof TaskEditState>(
      state: WritableDraft<TaskEditState>,
      action: PayloadAction<{ field: K; value: TaskEditState[K] }>,
    ) => {
      const { field, value } = action.payload;
      state[field] = value;
    },
  },
  selectors: {
    assigneeId: (state) => state.assigneeId,
    canSave: (state) => !!state.title && !!state.facility,
    /**
     * Build a `CreateTaskRequest` object from the current state. It does not
     * perform validation, it is assumed that validation is done before calling
     * this selector.
     */
    createTaskRequest: createSelector(
      [
        (state: TaskEditState) =>
          state.assigneeId === unassignedUser.id
            ? undefined
            : state.assigneeId || undefined,
        (state: TaskEditState) => state.description || undefined,
        (state: TaskEditState) => state.facility?.id,
        (state: TaskEditState) => state.priority,
        (state: TaskEditState) => state.recurrence,
        (state: TaskEditState) => state.date,
        (state: TaskEditState) => state.title,
        (state: TaskEditState) => state.zone?.id,
        (state: TaskEditState) => state.note || undefined,
      ],
      (
        assigneeId,
        description,
        facilityId,
        priority,
        recurrenceRule,
        startDate,
        title,
        zoneId,
        comment,
        // eslint-disable-next-line sonarjs/sonar-max-params
      ) =>
        ({
          assigneeId,
          comment,
          description,
          facilityId,
          priority,
          recurrenceRule,
          startDate,
          title,
          zoneId,
        }) as CreateTaskRequest,
    ),
    date: (state) => state.date,
    description: (state) => state.description,
    editTaskInstanceRequest: createSelector(
      [
        (state: TaskEditState) =>
          state.assigneeId === unassignedUser.id
            ? undefined
            : state.assigneeId || undefined,
        (state: TaskEditState) => state.priority,
        (state: TaskEditState) => state.date,
        (state: TaskEditState) => state.status,
        (state: TaskEditState) => state.originalDueDate,
        (state: TaskEditState) => state.seriesId,
      ],
      (assigneeId, priority, newDueDate, status, dueDate, seriesId) =>
        ({
          body: { assigneeId, newDueDate, priority, status },
          dueDate,
          seriesId,
        }) as {
          body: UpdateTaskInstanceRequest;
          dueDate: string;
          seriesId: string;
        },
    ),
    facility: (state) => state.facility,
    note: (state) => state.note,
    priority: (state) => state.priority,
    recurrence: (state) => state.recurrence,
    reportedBy: (state) => state.reportedBy,
    status: (state) => state.status,
    title: (state) => state.title,
    zone: (state) => state.zone,
  },
});
