import { configSlice } from 'features/config/configSlice';
import { taskEditSlice } from 'features/tasks/taskEditSlice';
import { createTask, updateTaskInstance } from 'features/tasks/tasksActions';
import { tokenSlice } from 'features/token/tokenSlice';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

import useToast from './useToast';

/**
 * Functions to save new tasks and edit existing tasks in the backend.
 */
export const useTaskEdit = () => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);

  const { setErrorMessage, setSuccessMessage } = useToast();

  const [saving, setSaving] = useState(false);

  const createTaskRequest = useSelector(
    taskEditSlice.selectors.createTaskRequest,
  );

  const editTaskInstanceRequest = useSelector(
    taskEditSlice.selectors.editTaskInstanceRequest,
  );

  const saveNewTask = async () => {
    setSaving(true);

    return dispatch(createTask({ baseUrl, task: createTaskRequest, token }))
      .unwrap()
      .then(() => setSuccessMessage('Task created successfully'))
      .catch(() => setErrorMessage('Failed to create task'))
      .finally(() => setSaving(false));
  };

  const editTaskInstance = async () => {
    setSaving(true);

    return dispatch(
      updateTaskInstance({ baseUrl, ...editTaskInstanceRequest, token }),
    )
      .unwrap()
      .then(() => setSuccessMessage('Task updated successfully'))
      .catch(() => setErrorMessage('Failed to update task'))
      .finally(() => setSaving(false));
  };

  return { editTaskInstance, saveNewTask, saving };
};
