import {
  CreateTaskRequest,
  ListTasksParams,
  TaskMedia,
  TasksClient,
  UpdateTaskInstanceRequest,
} from '@dakota/platform-client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ClientData, getClient } from 'features/clientProvider';

export const listTasks = createAsyncThunk(
  'tasks/listTasks',
  async (params: ClientData & ListTasksParams) => {
    const client = getClient(TasksClient, params);
    const response = await client.listTasks(params);
    return response.result;
  },
);

export const getTaskDetails = createAsyncThunk(
  'tasks/getTaskDetails',
  async (params: { date: string; seriesId: string } & ClientData) => {
    const client = getClient(TasksClient, params);
    const response = await client.getTaskInstance(params.seriesId, params.date);
    return response.result;
  },
);

export const createTask = createAsyncThunk(
  'tasks/createTask',
  async (params: { task: CreateTaskRequest } & ClientData) => {
    const client = getClient(TasksClient, params);

    const response = await client.createTaskSeries(params.task);
    return response.result;
  },
);

export const updateTaskInstance = createAsyncThunk(
  'tasks/updateTaskInstance',
  async (
    params: {
      body: UpdateTaskInstanceRequest;
      dueDate: string;
      seriesId: string;
    } & ClientData,
  ) => {
    const client = getClient(TasksClient, params);

    const response = await client.updateTaskInstance(
      params.seriesId,
      params.dueDate,
      params.body,
    );
    return response.result;
  },
);

export const updateTaskAttachment = createAsyncThunk(
  'tasks/updateTaskAttachment',
  async (
    params: {
      attachmentId: string;
      attachmentIndex: number;
      description: string;
      dueDate: string;
      seriesId: string;
      taskId?: string;
    } & ClientData,
  ) => {
    const client = getClient(TasksClient, params);
    const body = {
      description: params.description || undefined,
    } as TaskMedia;
    const response = await client.updateMediaForTask(
      params.dueDate,
      params.seriesId,
      params.attachmentId,
      body,
    );
    return response.result;
  },
);
