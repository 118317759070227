import { Facility } from '@dakota/platform-client';
import { PlusIcon } from '@heroicons/react/20/solid';
import { Dialog } from '@mui/material';
import PermissionGuard from 'auth/PermissionGuard';
import { clsx } from 'clsx';
import Autocomplete from 'components/Autocomplete';
import Button from 'components/Button';
import Chip from 'components/Chip';
import { ClearAllButton } from 'components/ClearAll';
import { PageHeader } from 'components/PageHeader';
import SearchInput from 'components/SearchInput';
import Table from 'components/Table';
import { ColumnProps } from 'components/Table/types';
import Tooltip from 'components/Tooltip';
import { configSlice } from 'features/config/configSlice';
import { getAllFacilities } from 'features/facilities/facilitiesActions';
import { facilitiesSlice } from 'features/facilities/facilitiesSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import Fuse from 'fuse.js';
import { DataStatus, usePageLoadTracking } from 'hooks/usePageLoadTracking';
import useToast from 'hooks/useToast';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { alphabeticalCompare } from 'utils/functional';
import { getHighlighter } from 'utils/highlighter';
import { Permission } from 'utils/permissions';
import { usaStateAbbreviations } from 'utils/state';

import AddEditFacility from './addFacility';
import FacilityMenu from './facilityMenu';

const Facilities: React.FC = () => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const allFacilities = useSelector(facilitiesSlice.selectors.allFacilities);

  const { stopTracking } = usePageLoadTracking();
  const { setErrorMessage, setSuccessMessage } = useToast();

  const [filteredFacilities, setFilteredFacilities] = useState<Facility[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedStates, setSelectedStates] = useState<string[]>([]);
  const [isFacilityModalOpen, setIsFacilityModalOpen] = useState(false);
  const [selectedFacilityForZones, setSelectedFacilityForZones] =
    useState<Facility | null>(null);

  type Status = 'Active' | 'All' | 'Inactive';
  const statuses = ['All', 'Active', 'Inactive'] as const;
  const [selectedStatus, setSelectedStatus] = useState<Status>('Active');

  useEffect(() => {
    void dispatch(getAllFacilities({ baseUrl, token })).then(() =>
      stopTracking(DataStatus.Fetched),
    );
  }, [baseUrl, dispatch, stopTracking, token]);

  useEffect(() => {
    let newFacilities = allFacilities;

    if (selectedStatus === 'Active') {
      newFacilities = newFacilities.filter((f) => !f.inactive);
    } else if (selectedStatus === 'Inactive') {
      newFacilities = newFacilities.filter((f) => f.inactive);
    }

    if (selectedStates.length > 0) {
      newFacilities = newFacilities.filter(
        (f) => f.address?.state && selectedStates.includes(f.address.state),
      );
    }

    if (searchQuery) {
      const fuse = new Fuse(newFacilities, {
        findAllMatches: true,
        ignoreLocation: true,
        keys: [
          { name: 'name', weight: 1 },
          { name: 'address.city', weight: 0.3 },
          { name: 'address.state', weight: 0.3 },
          { name: 'contact.name', weight: 0.3 },
        ],
        shouldSort: true,
        threshold: 0.2,
        useExtendedSearch: true,
      });
      newFacilities = fuse.search(searchQuery).map((r) => r.item);
    }

    setFilteredFacilities(
      newFacilities.toSorted(alphabeticalCompare((f) => f.name)),
    );
  }, [allFacilities, searchQuery, selectedStates, selectedStatus]);

  const textGray400 = 'text-gray-400';
  const getClasses = (inactive: boolean) =>
    `flex flex-col space-y-1 ${inactive ? textGray400 : ''}`;

  const highlight = getHighlighter(searchQuery);

  const zonesLength = (facility: Facility) => {
    const activeZones = facility.zones.filter((zone) => !zone.inactive);
    const inactiveZones = facility.zones.filter((zone) => zone.inactive);

    const activeCount = activeZones.length;
    const inactiveCount = inactiveZones.length;

    return activeCount === 0 ? (
      <Tooltip
        arrow
        enterTouchDelay={0}
        title={`${facility.name} has no zones`}
      >
        <div
          className={clsx(
            'inline-block text-xs rounded-md p-2',
            'bg-gray-100 text-gray-500 hover:cursor-help',
          )}
        >
          No zones
        </div>
      </Tooltip>
    ) : (
      <button
        className={clsx(
          'text-green-dark bg-green-lighter rounded-md',
          'text-xs p-2 hover:bg-green-light',
        )}
        onClick={() => setSelectedFacilityForZones(facility)}
      >
        {activeCount === 1 ? activeZones[0].name : `${activeCount} zones`}
        {inactiveCount > 0 && (
          <span className='text-xs italic text-gray-600 ml-2'>
            (+{inactiveCount} inactive)
          </span>
        )}
      </button>
    );
  };

  const columns: ReadonlyArray<ColumnProps<Facility>> = [
    {
      cellDataTestId: 'name',
      key: 'name',
      render: (facility) => {
        return (
          <p className={getClasses(facility.inactive)}>
            {highlight(facility.name)}
          </p>
        );
      },
      title: 'Facility Name',
    },
    {
      cellDataTestId: 'contact',
      key: 'contact',
      render: (facility) => {
        return (
          <p className={getClasses(facility.inactive)}>
            {highlight(facility.contact?.name)}
          </p>
        );
      },
      title: 'Contact',
    },
    {
      cellDataTestId: 'city',
      key: 'city',
      render: (facility) => {
        return (
          <p className={getClasses(facility.inactive)}>
            {highlight(facility.address?.city)}
          </p>
        );
      },
      title: 'City',
    },
    {
      cellDataTestId: 'state',
      key: 'state',
      render: (facility) => {
        return (
          <p className={getClasses(facility.inactive)}>
            {facility.address?.state}
          </p>
        );
      },
      title: 'State',
    },
    {
      cellDataTestId: 'zones',
      key: 'zones',
      render: zonesLength,
      title: 'Zones',
    },
    {
      cellDataTestId: 'menu',
      key: 'menu',
      render: (facility) => (
        <PermissionGuard
          permissions={[
            Permission.UpdateFacility,
            Permission.DeleteFacility,
            Permission.DeactivateFacility,
          ]}
        >
          <FacilityMenu facility={facility} />
        </PermissionGuard>
      ),
      title: '',
    },
  ];

  const resetFilters = () => {
    setSearchQuery('');
    setSelectedStates([]);
    setSelectedStatus('Active');
  };

  const handleAddFacilityFailure = () => {
    setErrorMessage('Failed to add facility');
  };

  const handleAddFacilitySuccess = () => {
    setIsFacilityModalOpen(false);
    setSuccessMessage('Facility added successfully');
  };

  const removeState = (state: string) => {
    setSelectedStates((prev) => prev.filter((s) => s !== state));
  };

  return (
    <div className='p-4 sm:p-8'>
      <PageHeader
        scaffold={['Facilities', 'My Organization']}
        title='Facilities'
      >
        <PermissionGuard permissions={Permission.CreateFacility}>
          <Button
            className='bg-green-base px-4 py-2'
            data-testid='add-facility-button'
            icon={<PlusIcon />}
            id='add-facility-button'
            onClick={() => setIsFacilityModalOpen(true)}
          >
            Add Facility
          </Button>
        </PermissionGuard>
      </PageHeader>
      <div className='filters-container'>
        <SearchInput
          data-testid='facilities-search-input'
          id='facilities-search-input'
          onSearch={(query: string) => setSearchQuery(query.toLowerCase())}
          value={searchQuery}
        />
        <div className='flex items-center gap-3'>
          <Autocomplete
            className='w-32'
            id='facilities-state-selector'
            label='State'
            multiple
            onChange={setSelectedStates}
            options={usaStateAbbreviations}
            value={selectedStates}
          />
          <PermissionGuard permissions={Permission.DeactivateFacility}>
            <Autocomplete
              className='w-28'
              id='facilities-status-selector'
              label='Status'
              onChange={setSelectedStatus}
              options={statuses}
              value={selectedStatus}
            />
          </PermissionGuard>
          {(selectedStates.length > 0 ||
            selectedStatus !== 'Active' ||
            searchQuery.length > 0) && (
            <ClearAllButton onClick={resetFilters} />
          )}
        </div>
      </div>
      {selectedStates.length > 0 && (
        <div className='flex items-center gap-2 pb-2'>
          <span className='text-sm font-normal pr-1'>States:</span>
          {selectedStates.map((state) => (
            <Chip
              key={state}
              onRemove={() => removeState(state)}
              text={state}
            />
          ))}
        </div>
      )}
      <Table
        columns={columns}
        data={filteredFacilities}
        footer={`${filteredFacilities.length} results`}
        id='facilities-table'
        loading={useSelector(facilitiesSlice.selectors.isLoadingAllFacilities)}
      />
      {isFacilityModalOpen && (
        <AddEditFacility
          handleFailure={handleAddFacilityFailure}
          handleSuccess={handleAddFacilitySuccess}
          isOpen={isFacilityModalOpen}
          setIsOpen={setIsFacilityModalOpen}
        />
      )}
      {selectedFacilityForZones && (
        <Dialog
          fullWidth
          maxWidth='xs'
          onClose={() => setSelectedFacilityForZones(null)}
          open
        >
          <div className='flex flex-col gap-3 p-6 text-gray-500'>
            <div className='text-xl font-semibold text-gray-900'>
              Zones for {selectedFacilityForZones.name}
            </div>
            <ol className='list-decimal list-inside' data-testid='zone-list'>
              {selectedFacilityForZones.zones.map((zone) => (
                <li
                  className={`${zone.inactive && 'text-gray-400'}`}
                  data-testid='zone-list-item'
                  key={zone.id}
                >
                  {zone.name} {zone.inactive && <span>(inactive)</span>}
                </li>
              ))}
            </ol>
          </div>
        </Dialog>
      )}
    </div>
  );
};

export default Facilities;
