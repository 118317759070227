import { TaskInstance, TaskInstanceDetails } from '@dakota/platform-client';
import { ClipboardDocumentCheckIcon } from '@heroicons/react/24/outline';
import { Dialog } from '@mui/material';
import { clsx } from 'clsx';
import { configSlice } from 'features/config/configSlice';
import { getTaskDetails } from 'features/tasks/tasksActions';
import { tokenSlice } from 'features/token/tokenSlice';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

import { EditTask } from './EditTask';

type TaskLoaderProps = {
  /**
   * Callback to close the task loader.
   */
  onClose: () => void;
  /**
   * The task instance to load.
   */
  taskInstance: TaskInstance;
};

export const TaskLoader: FC<TaskLoaderProps> = ({ onClose, taskInstance }) => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);

  const [task, setTask] = useState<TaskInstanceDetails | undefined>(undefined);

  useEffect(() => {
    void dispatch(
      getTaskDetails({
        baseUrl,
        date: taskInstance.timeline.scheduledDate.toString(),
        seriesId: taskInstance.seriesId,
        token,
      }),
    )
      .unwrap()
      .then(setTask);
  }, [
    baseUrl,
    dispatch,
    taskInstance.seriesId,
    taskInstance.timeline.scheduledDate,
    token,
  ]);

  if (!task) {
    return (
      <Dialog maxWidth='md' onClose={onClose} open>
        <div
          className={clsx(
            'p-8 flex flex-col gap-2 justify-center items-center',
            'text-lg text-gray-500 animate-pulse',
          )}
        >
          <ClipboardDocumentCheckIcon className='w-24 h-24' />
          Loading task details...
        </div>
      </Dialog>
    );
  }

  return <EditTask onClose={onClose} task={task} />;
};
