import {
  SearchStatus,
  Summary,
  TaskState,
  UserSummary,
} from '@dakota/platform-client';
import { configSlice } from 'features/config/configSlice';
import { listTasks } from 'features/tasks/tasksActions';
import { tokenSlice } from 'features/token/tokenSlice';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

import { useDateRange } from './useDateRange';

interface UseFilteredTasksParams {
  /**
   * If present, filter by text search
   * @default `undefined`
   */
  searchQuery?: string;
  /**
   * If present, filter by assignee
   * @default `undefined`
   */
  selectedAssignees?: UserSummary[];
  /**
   * If present, filter by facility
   * @default `undefined`
   */
  selectedFacilities?: Summary[];
  /**
   * If present, filter by status
   * @default `undefined`
   */
  selectedStatus?: TaskState[];
}

export const useFilteredTasks = ({
  searchQuery = '',
  selectedAssignees = undefined,
  selectedFacilities = undefined,
  selectedStatus = undefined,
}: UseFilteredTasksParams) => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);

  const {
    dateRange,
    initialBeginDate,
    initialEndDate,
    resetDateRange,
    setDateRange,
  } = useDateRange();

  const [hookDataLoaded, setHookDataLoaded] = useState(false);

  const hasFilters =
    !!searchQuery ||
    !dateRange.begin.equals(initialBeginDate) ||
    !dateRange.end.equals(initialEndDate) ||
    !!selectedAssignees?.length ||
    !!selectedFacilities?.length ||
    !!selectedStatus?.length;

  useEffect(
    () =>
      void dispatch(
        listTasks({
          baseUrl,
          dateRange_endDate: dateRange.end.toString(),
          dateRange_startDate: dateRange.begin.toString(),
          status: [
            SearchStatus.Scheduled,
            SearchStatus.InProgress,
            SearchStatus.Overdue,
          ],
          token,
        }),
      ).then(() => {
        setHookDataLoaded(true);
      }),
    [token, dispatch, baseUrl, dateRange],
  );

  return {
    dateRange,
    hasFilters,
    hookDataLoaded,
    resetDateRange,
    setDateRange,
  };
};
