import { InspectionStatus } from '@dakota/platform-client';
import { ArrowPathIcon, BellIcon } from '@heroicons/react/24/outline';
import { Badge, IconButton, Menu, styled } from '@mui/material';
import { useCacheBuster } from 'cacheBuster';
import { notificationsSlice } from 'features/notifications/notificationsSlice';
import { FC, Key, ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import resolveConfig from 'tailwindcss/resolveConfig';
import { formatBackendDate } from 'utils/date';

import tailwindConfig from '../../../tailwind.config';
import { NotificationsPanel } from './NotificationsPanel';

const { theme } = resolveConfig(tailwindConfig);

export type Notification = {
  cta: {
    /**
     * The link to navigate to when the CTA is clicked.
     * If a function is passed, the function will be executed.
     */
    link: (() => void) | string;
    text: string;
  };
  key: Key;
  message: ReactElement | string;
  /**
   * If the notification is not 'normal', it will be styled with
   * a background color according to the severity.
   * @default 'normal'
   */
  severity?: 'normal' | 'warning';
};

export const Notifications: FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isPanelOpen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const pendingInspections = useSelector(
    notificationsSlice.selectors.pendingInspections,
  );

  const actualScheduled = pendingInspections.filter(
    (i) => i.status === InspectionStatus.Scheduled,
  );
  const actualOverdue = pendingInspections.filter(
    (i) => i.status === InspectionStatus.Overdue,
  );

  const notifications: Notification[] = [];

  const { clearCacheAndReload, initialized, versions } = useCacheBuster();
  const hasNewVersion = initialized && !!versions.current && !versions.isLatest;
  if (hasNewVersion) {
    notifications.push({
      cta: {
        link: () => void clearCacheAndReload(),
        text: 'Refresh',
      },
      key: 'new-version',
      message: (
        <div className='flex gap-2 justify-stretch items-center'>
          <div className='flex-none'>
            <ArrowPathIcon className='w-10 h-10 bg-green-lighter rounded-full p-2' />
          </div>
          <div className='flex-1'>
            We&apos;ve updated our application. Please refresh this page to
            avoid errors.
          </div>
        </div>
      ),
    });
  }

  actualOverdue.forEach((inspection) => {
    notifications.push({
      cta: {
        link: `/inspections/${inspection.id}`,
        text: 'Go to Inspection',
      },
      key: inspection.id,
      message: (
        <>
          Your inspection <strong>{inspection.form.name}</strong> is overdue. It
          was scheduled for{' '}
          {formatBackendDate(inspection.timeline.scheduledDate)}.
        </>
      ),
      severity: 'warning',
    });
  });

  actualScheduled.forEach((inspection) => {
    notifications.push({
      cta: {
        link: `/inspections/${inspection.id}`,
        text: 'Go to Inspection',
      },
      key: inspection.id,
      message: (
        <>
          Your inspection <strong>{inspection.form.name}</strong> is due today.
        </>
      ),
    });
  });

  /**
   * Modified from an example in MUI documentation to have
   * the badge icon exactly on the border of the avatar circle.
   */
  const StyledBadge = styled(Badge)(() => ({
    '@keyframes ripple': {
      '0%': {
        border: `1px solid ${theme.colors.red.base}`,
        opacity: 1,
        transform: 'scale(.8)',
      },
      '100%': {
        border: `1px solid ${theme.colors.red.light}`,
        opacity: 0,
        transform: 'scale(2.4)',
      },
    },
    '& .MuiBadge-badge': {
      '&::after': {
        animation: 'ripple 1.2s 1 ease-in-out',
        borderRadius: '50%',
        content: '""',
        height: '100%',
        left: 0,
        position: 'absolute',
        top: 0,
        width: '100%',
      },
      backgroundColor: theme.colors.red.base,
      color: theme.colors.white,
    },
  }));

  return (
    <>
      <IconButton
        aria-controls={anchorEl ? 'notifications-panel' : undefined}
        aria-expanded={anchorEl ? 'true' : undefined}
        aria-haspopup='true'
        aria-label='Notifications'
        id='notifications-button'
        onClick={handleClick}
      >
        <StyledBadge badgeContent={notifications.length}>
          <BellIcon className='w-7 h-7' />
        </StyledBadge>
      </IconButton>
      {isPanelOpen && (
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          aria-labelledby='notifications-button'
          id='notifications-panel'
          onClose={() => setAnchorEl(null)}
          open
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
          <NotificationsPanel
            notifications={notifications}
            onClose={() => setAnchorEl(null)}
          />
        </Menu>
      )}
    </>
  );
};
