import {
  Inspection,
  InspectionStatus,
  Summary,
  UserSummary,
} from '@dakota/platform-client';
import { LocalDate } from '@js-joda/core';
import Autocomplete from 'components/Autocomplete';
import Chips from 'components/Chip/Chips';
import { ClearAllButton } from 'components/ClearAll';
import { DatePicker, DatePickerRange } from 'components/DatePicker';
import InspectionsTable from 'components/Inspections/InspectionsTable';
import { PageHeader } from 'components/PageHeader';
import SearchInput from 'components/SearchInput';
import { configSlice } from 'features/config/configSlice';
import { listInspections } from 'features/inspections/inspectionActions';
import { inspectionSlice } from 'features/inspections/inspectionSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import { userSlice } from 'features/user/userSlice';
import Fuse from 'fuse.js';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { DataStatus, usePageLoadTracking } from 'hooks/usePageLoadTracking';
import { useUsers } from 'hooks/useUsers';
import { useViewToggle } from 'hooks/useViewToggle';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/store';
import { FeatureFlag } from 'utils/featureFlags';
import { alphabeticalCompare } from 'utils/functional';
import { getStatusLabel } from 'utils/inspectionStatusLabel';

import { InspectionCard } from '../Card';

const CompletedInspections: React.FC = () => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const isMobile = useSelector(userSlice.selectors.isMobile);

  const isTasksEnabled = useFeatureFlag(FeatureFlag.Tasks);

  const navigate = useNavigate();
  const location = useLocation();

  const accessibleFacilities = useSelector(
    userSlice.selectors.accessibleFacilities,
  );
  const selectableFacilities = accessibleFacilities.toSorted(
    alphabeticalCompare((e) => e.name),
  );

  const allInspections = useSelector(inspectionSlice.selectors.inspections);
  const loadingInspections = useSelector(
    inspectionSlice.selectors.isLoadingInspections,
  );
  const { activeUsers } = useUsers();

  const [searchQuery, setSearchQuery] = useState('');
  const [filteredInspections, setFilteredInspections] = useState<Inspection[]>(
    [],
  );

  const statuses = [InspectionStatus.Completed, InspectionStatus.Canceled];
  const [selectedFacilities, setSelectedFacilities] = useState<Summary[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<InspectionStatus>(
    InspectionStatus.Completed,
  );

  const currentDate = useMemo(() => LocalDate.now(), []);
  const thirtyDaysAgo = useMemo(() => currentDate.minusDays(30), [currentDate]);
  const [dateRange, setDateRange] = useState<DatePickerRange>({
    begin: thirtyDaysAgo,
    end: currentDate,
  });

  const [selectedAssignees, setSelectedAssignees] = useState<UserSummary[]>([]);
  const [isClearButtonVisible, setIsClearButtonVisible] = useState(false);

  const { isListView, viewToggleButtons } = useViewToggle(
    'completedInspectionsView',
  );

  const { stopTracking } = usePageLoadTracking();

  useEffect(
    () =>
      void dispatch(
        listInspections({
          baseUrl,
          status: [InspectionStatus.Completed, InspectionStatus.Canceled],
          token,
        }),
      ).then(() => stopTracking(DataStatus.Fetched)),
    [baseUrl, dispatch, stopTracking, token],
  );

  useEffect(() => {
    let newInspections = allInspections;

    if (selectedStatus === InspectionStatus.Completed) {
      newInspections = newInspections.filter(
        (q) => q.status === InspectionStatus.Completed,
      );
    } else {
      newInspections = newInspections.filter(
        (q) => q.status === InspectionStatus.Canceled,
      );
    }

    if (selectedFacilities.length > 0) {
      newInspections = newInspections.filter((inspection) =>
        selectedFacilities.find(
          (facility) => facility.id === inspection.facility.id,
        ),
      );
    }

    if (selectedAssignees.length > 0) {
      newInspections = newInspections.filter((inspection) =>
        selectedAssignees.find((assignee) => assignee.id === inspection.userId),
      );
    }

    newInspections = newInspections.filter((inspection) => {
      const inspectionEndDate = LocalDate.parse(
        inspection.timeline.endDate as string,
      );

      return (
        !dateRange.begin.isAfter(inspectionEndDate) &&
        !inspectionEndDate.isAfter(dateRange.end)
      );
    });

    if (searchQuery) {
      const fuse = new Fuse(newInspections, {
        findAllMatches: true,
        ignoreLocation: true,
        keys: [
          { name: 'form.name', weight: 1 },
          { name: 'facility.name', weight: 1 },
          { name: 'userName', weight: 0.8 },
        ],
        shouldSort: true,
        threshold: 0.2,
        useExtendedSearch: true,
      });
      newInspections = fuse.search(searchQuery).map((e) => e.item);
    }

    /**
     * This codeblock handles sorting the completed inspections by the date on
     * which they were completed. More recently completed inspections are at
     * the top, sorted in descending order.
     */
    newInspections.sort((a, b) =>
      (b.timeline.endDate as string).localeCompare(
        a.timeline.endDate as string,
      ),
    );

    setFilteredInspections(newInspections);
  }, [
    allInspections,
    dateRange.begin,
    dateRange.end,
    searchQuery,
    selectedAssignees,
    selectedFacilities,
    selectedStatus,
  ]);

  const removeFacility = (facility: Summary) => {
    setSelectedFacilities((prev) => prev.filter((f) => f.id !== facility.id));
  };

  const removeAssignee = (assignee: UserSummary) => {
    setSelectedAssignees((prev) => prev.filter((f) => f.id !== assignee.id));
  };

  const resetFilters = () => {
    setSearchQuery('');
    setSelectedAssignees([]);
    setSelectedFacilities([]);
    setSelectedStatus(InspectionStatus.Completed);
    setDateRange({
      begin: thirtyDaysAgo,
      end: currentDate,
    });
  };

  useEffect(() => {
    setIsClearButtonVisible(
      selectedAssignees.length > 0 ||
        selectedFacilities.length > 0 ||
        selectedStatus !== InspectionStatus.Completed ||
        !dateRange.begin.equals(thirtyDaysAgo) ||
        !dateRange.end.equals(currentDate) ||
        !!searchQuery,
    );
  }, [
    currentDate,
    searchQuery,
    selectedAssignees.length,
    dateRange.end,
    dateRange.begin,
    selectedFacilities.length,
    selectedStatus,
    thirtyDaysAgo,
  ]);

  const openInspection = (inspection: Inspection) => {
    navigate(`/inspections/${inspection.id}?source=${location.pathname}`);
  };

  return (
    <div className='p-4 sm:p-8'>
      <PageHeader scaffold={['Completed', 'Inspections']} title='Completed' />
      <div className='flex justify-between items-center gap-2'>
        <div className='filters-container'>
          <SearchInput
            data-testid='search-input'
            onSearch={setSearchQuery}
            value={searchQuery}
          />
          <DatePicker
            asSingle={false}
            id='completed-inspections-date'
            onChange={setDateRange}
            placeholder='date range'
            popoverDirection='down'
            showShortcuts
            value={dateRange}
          />
          <Autocomplete
            className='max-sm:w-60 w-40'
            getOptionKey={(option) => option.id}
            getOptionLabel={(option) => option.name}
            id='completed-inspections-facilities-selector'
            label='Facilities'
            multiple
            onChange={setSelectedFacilities}
            options={selectableFacilities}
            value={selectedFacilities}
          />
          <Autocomplete
            className='max-sm:w-60 w-40'
            getOptionKey={(option) => option.id}
            getOptionLabel={(option) => option.name}
            id='completed-inspections-assignee-selector'
            label='Assignee'
            multiple
            onChange={setSelectedAssignees}
            options={activeUsers}
            value={selectedAssignees}
          />
          <Autocomplete
            className='w-36'
            getOptionLabel={getStatusLabel}
            id='completed-inspections-status-selector'
            label='Status'
            onChange={setSelectedStatus}
            options={statuses}
            value={selectedStatus}
          />
          {isClearButtonVisible && <ClearAllButton onClick={resetFilters} />}
        </div>
        {isTasksEnabled && viewToggleButtons}
      </div>
      <Chips
        elements={selectedFacilities}
        getKey={(f) => f.id}
        getLabel={(f) => f.name}
        onRemove={removeFacility}
      />
      <Chips
        elements={selectedAssignees}
        getKey={(f) => f.id}
        getLabel={(f) => f.name}
        onRemove={removeAssignee}
      />
      {isMobile || isListView || !isTasksEnabled ? (
        <InspectionsTable
          data={filteredInspections}
          loading={loadingInspections}
          searchQuery={searchQuery}
          showCompletedDate
        />
      ) : (
        <div className='space-y-4'>
          {filteredInspections.map((inspection) => (
            <InspectionCard
              inspection={inspection}
              key={inspection.id}
              openInspection={() => openInspection(inspection)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default CompletedInspections;
