import { Priority, TaskState } from '@dakota/platform-client';

export type ExtendedTaskState = 'overdue' | TaskState;

export const getStatusLabel = (status: ExtendedTaskState) => {
  switch (status) {
    case 'overdue':
      return 'Overdue';
    case TaskState.Canceled:
      return 'Canceled';
    case TaskState.Completed:
      return 'Completed';
    case TaskState.InProgress:
      return 'In Progress';
    case TaskState.Scheduled:
      return 'Scheduled';
  }
};

export const getPriorityLabel = (priority: Priority) => {
  switch (priority) {
    case Priority.High:
      return 'High';
    case Priority.Low:
      return 'Low';
    case Priority.Medium:
      return 'Medium';
  }
};
