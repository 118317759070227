import { Inspection } from '@dakota/platform-client';
import { PriorityIcon } from 'components/PriorityIcon';
import { FC } from 'react';

type InspectionDetailsButtonProps = {
  inspection: Inspection;
  onClick: () => void;
};

export const InspectionDetailsButton: FC<InspectionDetailsButtonProps> = ({
  inspection,
  onClick,
}) => {
  return (
    <button className='flex-1 flex sm:items-center gap-4' onClick={onClick}>
      <div>
        Due: <strong>{inspection.timeline.scheduledDate}</strong>
      </div>
      <div className='flex items-center gap-1'>
        Priority: <PriorityIcon priority={inspection.priority} />
      </div>
      <div>
        Facility: <strong>{inspection.facility.name}</strong>
      </div>
    </button>
  );
};
