import { Summary } from '@dakota/platform-client';
import { configSlice } from 'features/config/configSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import { listZones } from 'features/zones/zonesActions';
import { zonesSlice } from 'features/zones/zonesSlice';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

export const useZones = () => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);

  const [loading, setLoading] = useState<string[]>([]);

  const zonesPerFacility = useSelector(zonesSlice.selectors.zonesPerFacility);

  const startLoading = (facilityId: string) =>
    setLoading((prev) => [...prev, facilityId]);

  const completeLoading = (facilityId: string) =>
    setLoading((prev) => prev.filter((id) => id !== facilityId));

  const getFacilityZones = useCallback(
    (facilityId: string) => {
      if (!zonesPerFacility.has(facilityId) && !loading.includes(facilityId)) {
        startLoading(facilityId);
        void dispatch(listZones({ baseUrl, facilityId, token })).then(() =>
          completeLoading(facilityId),
        );
      }
      return zonesPerFacility.get(facilityId) ?? [];
    },
    [baseUrl, dispatch, loading, token, zonesPerFacility],
  );

  const getFacilityZonesSummaries = useCallback(
    (facilityId: string) =>
      getFacilityZones(facilityId).map((zone) => Summary.fromJS(zone)),
    [getFacilityZones],
  );

  return {
    /**
     * The list of facility IDs for which zones are currently being fetched.
     * Useful for loading states.
     */
    facilitiesLoadingZones: loading,
    getFacilityZones,
    getFacilityZonesSummaries,
  };
};
