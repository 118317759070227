import { LocalDate } from '@js-joda/core';
import { DatePickerRange } from 'components/DatePicker';
import { useCallback, useMemo, useState } from 'react';

export const useDateRange = () => {
  const initialBeginDate = useMemo(() => LocalDate.now(), []);
  const initialEndDate = useMemo(
    () => initialBeginDate.plusDays(7),
    [initialBeginDate],
  );

  const [dateRange, setDateRange] = useState<DatePickerRange>({
    begin: initialBeginDate,
    end: initialEndDate,
  });

  const resetDateRange = useCallback(() => {
    setDateRange({ begin: initialBeginDate, end: initialEndDate });
  }, [initialBeginDate, initialEndDate]);

  return {
    dateRange,
    initialBeginDate,
    initialEndDate,
    resetDateRange,
    setDateRange,
  };
};
