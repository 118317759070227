import { Inspection, InspectionStatus } from '@dakota/platform-client';
import { AssigneeAvatar } from 'components/Avatar';
import Table from 'components/Table';
import { ColumnProps } from 'components/Table/types';
import { userSlice } from 'features/user/userSlice';
import { useUsers } from 'hooks/useUsers';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { formatBackendDate } from 'utils/date';
import { getHighlighter } from 'utils/highlighter';

import ActionMenu from './ActionMenu';
import { PrintAlt } from './PrintAlt';
import { ResultsBar } from './ResultsBar';
import { ZoneCell } from './ZoneCell';

interface InspectionsTableProps {
  data: Inspection[];
  loading: boolean;
  /**
   * If provided, it will highlight the search query in a few columns.
   * @default `undefined`
   */
  searchQuery?: string;
  /**
   * If `true`, the Due Date column will be replaced with the Completed Date
   * column The Completed Date column works similarly to Due Date, but the
   * former renders inspection.timeline.endDate whereas the latter renders
   * inspection.timeline.scheduledDate
   * @default `false`
   */
  showCompletedDate?: boolean;
}

const statusDisplayMap: { [key: string]: string } = {
  canceled: 'Canceled',
  completed: 'Completed',
  inprogress: 'In Progress',
  overdue: 'Overdue',
  scheduled: 'Scheduled',
};

const getDisplayStatus = (status: string): string => {
  const lowerCaseStatus = status.toLowerCase();
  return statusDisplayMap[lowerCaseStatus];
};

const InspectionsTable: FC<InspectionsTableProps> = ({
  data,
  loading,
  searchQuery = undefined,
  showCompletedDate = false,
}) => {
  const currentUser = useSelector(userSlice.selectors.currentUser);

  const highlight = getHighlighter(searchQuery ?? '');

  const { getUserSummary } = useUsers();

  const columns: ReadonlyArray<ColumnProps<Inspection>> = [
    {
      cellDataTestId: 'title',
      key: 'title',
      render: (inspection) =>
        inspection.status !== InspectionStatus.Canceled &&
        (currentUser.id === inspection.userId ||
          inspection.status === InspectionStatus.Completed) ? (
          <Link
            className='text-green-base hover:text-green-darker'
            to={`/inspections/${inspection.id}?source=${location.pathname}`}
          >
            {highlight(inspection.form.name)}
          </Link>
        ) : (
          highlight(inspection.form.name)
        ),
      title: 'Inspection Title',
    },
    showCompletedDate
      ? {
          cellDataTestId: 'completedDate',
          key: 'completedDate',
          render: (inspection) =>
            formatBackendDate(inspection.timeline.endDate as string),
          title: 'Completed Date',
        }
      : {
          cellDataTestId: 'dueDate',
          key: 'dueDate',
          render: (inspection) =>
            formatBackendDate(inspection.timeline.scheduledDate),
          title: 'Due Date',
        },
    {
      cellDataTestId: 'facility',
      key: 'facility',
      render: (inspection) => highlight(inspection.facility.name),
      title: 'Facility',
    },
    {
      cellDataTestId: 'zone',
      key: 'zone',
      render: (inspection) => (
        <ZoneCell name={inspection.form.name} zones={inspection.zones} />
      ),
      title: 'Zone',
    },
    {
      cellDataTestId: 'assignee',
      key: 'assignee',
      render: (inspection) => {
        return <AssigneeAvatar user={getUserSummary(inspection.userId)} />;
      },
    },
    {
      cellDataTestId: 'status',
      key: 'status',
      render: (inspection) => getDisplayStatus(inspection.status),
      title: 'Status',
    },
    {
      cellDataTestId: 'results',
      key: 'results',
      render: (inspection) => (
        <ResultsBar prompts={inspection.prompts} status={inspection.status} />
      ),
      title: 'Results',
    },
    {
      cellDataTestId: 'actions',
      key: 'actions',
      render: (inspection) => {
        if (inspection.status === InspectionStatus.Completed) {
          return <PrintAlt inspection={inspection} />;
        } else if (inspection.status !== InspectionStatus.Canceled) {
          return <ActionMenu inspection={inspection} />;
        }
      },
      title: 'Actions',
    },
  ];

  return (
    <Table
      {...{ columns, data, loading }}
      footer={`${data.length} results`}
      id='inspections-table'
    />
  );
};

export default InspectionsTable;
