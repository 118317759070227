import { Inspection, InspectionStatus } from '@dakota/platform-client';
import { UserIcon } from '@heroicons/react/24/solid';
import { Dialog, Tooltip } from '@mui/material';
import { clsx } from 'clsx';
import Autocomplete from 'components/Autocomplete';
import Button from 'components/Button';
import { configSlice } from 'features/config/configSlice';
import { updateInspection } from 'features/inspections/inspectionActions';
import { tokenSlice } from 'features/token/tokenSlice';
import { listFacilityUsers } from 'features/user/userActions';
import { userSlice } from 'features/user/userSlice';
import { useCheckPermission } from 'hooks/useCheckPermission';
import useToast from 'hooks/useToast';
import { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { formatBackendDate } from 'utils/date';
import { Permission } from 'utils/permissions';
import { unassignedUser } from 'utils/user';

type Props = {
  inspection: Inspection;
};

export const Reassign: FC<Props> = ({ inspection }) => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);

  const canUpdateInspection = useCheckPermission(Permission.UpdateInspection);
  const canListUsers = useCheckPermission([
    Permission.ReadUser,
    Permission.ManageUsers,
  ]);
  const isAdmin = useCheckPermission(Permission.Admin);

  const { setErrorMessage, setSuccessMessage } = useToast();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isAutocompleteOpen, setIsAutocompleteOpen] = useState(false);

  const usersPerFacility = useSelector(userSlice.selectors.usersPerFacility);
  const isLoadingFacilityUsers = useSelector(
    userSlice.selectors.isLoadingFacilityUsers,
  );

  const selectableUsers = useMemo(
    () =>
      [unassignedUser].concat(
        usersPerFacility.get(inspection.facility.id) ?? [],
      ),
    [inspection.facility.id, usersPerFacility],
  );

  const [assignee, setAssignee] = useState(
    selectableUsers.find((u) => u.id === inspection.userId) ?? unassignedUser,
  );

  let isDisabled =
    inspection.status === InspectionStatus.InProgress ||
    inspection.status === InspectionStatus.Completed ||
    inspection.status === InspectionStatus.Canceled;

  // For Overdue or Scheduled inspections,
  // allow if the user is an admin or has update permissions,
  // but only for users who can list users too,
  // as that's needed for reassigning.
  if (!isDisabled) {
    isDisabled = !(isAdmin || canUpdateInspection) && canListUsers;
  }

  useEffect(() => {
    // Only dispatch the action if the users for the facility are not loaded,
    // _and_ when the dialog is open, to avoid unnecessary API calls.
    if (
      isDialogOpen &&
      canListUsers &&
      !usersPerFacility.get(inspection.facility.id)
    ) {
      const listFacilityUsersData = {
        active: true,
        baseUrl,
        directFacilityOnly: false,
        facilityId: inspection.facility.id,
        orgWide: false,
        token,
      };

      void dispatch(listFacilityUsers(listFacilityUsersData));
    }
  }, [
    baseUrl,
    canListUsers,
    dispatch,
    inspection.facility.id,
    isDialogOpen,
    token,
    usersPerFacility,
  ]);

  useEffect(() => {
    // If the inspection has an assigned user and the assignee is unassigned,
    // it's because we hadn't fetched the selectable users yet when the
    // component mounted, so we do that now, once.
    if (inspection.userId && assignee.id === unassignedUser.id) {
      const user = selectableUsers.find((u) => u.id === inspection.userId);
      if (user) {
        setAssignee(user);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inspection.userId, selectableUsers]);

  const save = () => {
    setIsUpdating(true);

    const updateData = {
      baseUrl,
      id: inspection.id,
      priority: inspection.priority,
      scheduledDate: inspection.timeline.scheduledDate,
      token,
      userId: assignee.id === unassignedUser.id ? undefined : assignee.id,
      zones: inspection.zones.map((z) => z.id),
    };
    dispatch(updateInspection(updateData))
      .unwrap()
      .then(() => {
        setSuccessMessage('Inspection reassigned successfully');
        setIsDialogOpen(false);
      })
      .catch(() => {
        setErrorMessage('Failed to reassign inspection');
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  return (
    <>
      <button
        aria-label='Reassign Inspection'
        className={clsx(
          'flex items-center gap-3 cursor-pointer text-gray-700',
          'disabled:cursor-not-allowed disabled:text-gray-300',
        )}
        data-testid='reassign-inspection-button'
        disabled={isDisabled}
        onClick={() => setIsDialogOpen(true)}
      >
        <Tooltip arrow placement='top' title='Reassign Inspection'>
          <UserIcon className='w-4' />
        </Tooltip>
        Reassign
      </button>
      {isDialogOpen && (
        <Dialog
          disableEscapeKeyDown
          onClose={() => setIsDialogOpen(false)}
          open
        >
          <div
            className={clsx(
              'p-4 sm:w-96 text-gray-700',
              'flex flex-col justify-between',
              { 'min-h-[540px]': isAutocompleteOpen },
            )}
          >
            <div>
              <div className='text-lg font-medium'>Reassign Inspection</div>
              <div className='text-sm text-gray-500 mt-4 mb-6'>
                Select a user to reassign{' '}
                <span className='font-medium'>{inspection.form.name}</span>
                {', '}
                due on{' '}
                <span className='font-medium'>
                  {formatBackendDate(inspection.timeline.scheduledDate)}
                  {'.'}
                </span>
              </div>
              <Autocomplete
                getOptionKey={(option) => option.id}
                getOptionLabel={(option) => option.name}
                id='assignee-select'
                label='Select an assignee'
                loading={isLoadingFacilityUsers}
                onChange={setAssignee}
                onClose={() => setIsAutocompleteOpen(false)}
                onOpen={() => setIsAutocompleteOpen(true)}
                options={selectableUsers}
                value={assignee}
              />
            </div>
            <div className='flex'>
              <Button
                className='mt-6'
                disabled={isUpdating || assignee.id === inspection.userId}
                loading={isUpdating}
                onClick={save}
              >
                {isUpdating ? 'Saving...' : 'Save'}
              </Button>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
};
