import { XMarkIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import { FC } from 'react';

type Props = {
  onClose: () => void;
  title: string;
};

export const Header: FC<Props> = ({ onClose, title }) => {
  return (
    <div
      className={clsx(
        'flex-none flex justify-between items-center',
        'min-h-16 p-4 bg-green-base text-white',
      )}
    >
      <div className='text-xl font-semibold' id='task-title'>
        {title}
      </div>
      <button
        aria-label='Close'
        className='flex items-center justify-center h-full'
        id='close-create-task'
        onClick={onClose}
      >
        <XMarkIcon className='h-6 w-6' />
      </button>
    </div>
  );
};
