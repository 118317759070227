import {
  Inspection,
  InspectionStatus,
  Summary,
  UserSummary,
} from '@dakota/platform-client';
import { LocalDate } from '@js-joda/core';
import { configSlice } from 'features/config/configSlice';
import { listInspections } from 'features/inspections/inspectionActions';
import { inspectionSlice } from 'features/inspections/inspectionSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import Fuse from 'fuse.js';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

import { useDateRange } from './useDateRange';

interface UseFilteredInspectionsParams {
  /**
   * If present, only inspections assigned to this user will be included,
   * and the assignee filter will be disabled.
   * @default `undefined`
   */
  assigneeId?: string;
  /**
   * If present, filter by text search
   * @default `undefined`
   */
  searchQuery?: string;
  /**
   * If present, filter by assignee
   * @default `undefined`
   */
  selectedAssignees?: UserSummary[];
  /**
   * If present, filter by facility
   * @default `undefined`
   */
  selectedFacilities?: Summary[];
  /**
   * If present, filter by status
   * @default `undefined`
   */
  selectedStatus?: InspectionStatus[];
}

export const useFilteredInspections = ({
  assigneeId = undefined,
  searchQuery = '',
  selectedAssignees = undefined,
  selectedFacilities = undefined,
  selectedStatus = undefined,
}: UseFilteredInspectionsParams) => {
  const dispatch = useAppDispatch();
  const allInspections = useSelector(inspectionSlice.selectors.inspections);
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const [filteredInspections, setFilteredInspections] = useState<Inspection[]>(
    [],
  );

  const {
    dateRange,
    initialBeginDate,
    initialEndDate,
    resetDateRange,
    setDateRange,
  } = useDateRange();

  const [hookDataLoaded, setHookDataLoaded] = useState(false);

  const hasFilters =
    !!searchQuery ||
    !dateRange.begin.equals(initialBeginDate) ||
    !dateRange.end.equals(initialEndDate) ||
    !!selectedAssignees?.length ||
    !!selectedFacilities?.length ||
    !!selectedStatus?.length;

  /**
   * If there's an assignee ID, we only show inspections assigned to that user.
   *
   * Otherwise, we only filter if there are selected assignees. If there are
   * none, all inspections pass this filter.
   */
  const isAssignedToUser = useCallback(
    (inspection: Inspection, assignees?: UserSummary[]) => {
      if (assigneeId !== undefined) {
        return inspection.userId === assigneeId;
      }
      return (
        !assignees?.length ||
        assignees.some((assignee) => inspection.userId === assignee.id)
      );
    },
    [assigneeId],
  );

  // Only checks for matching status if there are any selected; otherwise,
  // all pass this filter.
  const matchesStatus = (
    inspection: Inspection,
    statuses?: InspectionStatus[],
  ) => !statuses?.length || statuses.includes(inspection.status);

  // Only checks for matching facility if there are any selected; otherwise,
  // all pass this filter.
  const isAtFacility = (inspection: Inspection, facilities?: Summary[]) =>
    !facilities?.length ||
    facilities.some((facility) => facility.id === inspection.facility.id);

  // Determines if the inspection's scheduled date falls within the selected
  // date range
  const isInDateRange = useCallback(
    (inspection: Inspection) => {
      const inspectionDate = LocalDate.parse(inspection.timeline.scheduledDate);
      return (
        !inspectionDate.isBefore(dateRange.begin) &&
        !inspectionDate.isAfter(dateRange.end)
      );
    },
    [dateRange.begin, dateRange.end],
  );

  // Filters inspections based on all criteria (assignee, status, facility,
  // date range)
  const filterInspections = useCallback(
    (inspections: Inspection[], skipDateCheck = false) => {
      return inspections.filter(
        (inspection) =>
          isAssignedToUser(inspection, selectedAssignees) &&
          matchesStatus(inspection, selectedStatus) &&
          isAtFacility(inspection, selectedFacilities) &&
          (skipDateCheck || isInDateRange(inspection)),
      );
    },
    [
      isAssignedToUser,
      isInDateRange,
      selectedAssignees,
      selectedFacilities,
      selectedStatus,
    ],
  );

  useEffect(() => {
    void dispatch(
      listInspections({
        baseUrl,
        status: [
          InspectionStatus.Overdue,
          InspectionStatus.Scheduled,
          InspectionStatus.InProgress,
        ],
        token,
      }),
    ).then(() => {
      setHookDataLoaded(true);
    });
  }, [token, dispatch, baseUrl]);

  useEffect(() => {
    // Combine filtered inspections with overdue and in progress inspections
    let newFilteredInspections = filterInspections(allInspections);

    // Ignore overdue and in progress by adding all of them
    const overdueAndInProgressInspections = filterInspections(
      allInspections.filter(
        (i) =>
          i.status === InspectionStatus.Overdue ||
          i.status === InspectionStatus.InProgress,
      ),
      true,
    );
    // Remove duplicates
    newFilteredInspections = [
      ...overdueAndInProgressInspections,
      ...newFilteredInspections.filter(
        (i) =>
          i.status !== InspectionStatus.Overdue &&
          i.status !== InspectionStatus.InProgress,
      ),
    ];

    // Apply search query if present
    if (searchQuery) {
      const fuse = new Fuse(newFilteredInspections, {
        findAllMatches: true,
        ignoreLocation: true,
        keys: [
          { name: 'form.name', weight: 1 },
          { name: 'facility.name', weight: 1 },
          { name: 'userName', weight: 0.8 },
          { name: 'status', weight: 0.8 },
        ],
        shouldSort: true,
        threshold: 0.2,
        useExtendedSearch: true,
      });
      newFilteredInspections = fuse
        .search(searchQuery)
        .map((result) => result.item);
    }

    setFilteredInspections(newFilteredInspections);
  }, [allInspections, filterInspections, searchQuery]);

  return {
    dateRange,
    filteredInspections,
    hasFilters,
    hookDataLoaded,
    resetDateRange,
    setDateRange,
  };
};
