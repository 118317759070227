import { PlusIcon } from '@heroicons/react/24/outline';
import { FC } from 'react';

export const Notes: FC = () => {
  return (
    <div className='flex flex-col gap-4'>
      <div className='text-base font-bold'>Notes</div>
      <button className='flex gap-2 items-center text-green-base text-sm'>
        <PlusIcon className='w-5' /> New Entry
      </button>
    </div>
  );
};
